import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

interface ImageProps extends React.ComponentPropsWithoutRef<'img'> {
  src: string
  aspectRatio?: string
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  placeholder?: string
}

const convertTypeToStrPx = (value: string | number | undefined) => {
  if (typeof value === 'number') {
    return `${value}px`
  }
  return value
}

export const Image = (props: ImageProps) => {
  const { src, width, height, placeholder, aspectRatio, objectFit, onLoad, style, ...rest } = props
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const fallbackImage = placeholder || `${process.env.HOST}/images/product_placeholder_375.svg`
  const [imageSrc, setImageSrc] = useState<string>(fallbackImage)
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  const handleError = () => {
    setHasError(true)
    setIsLoading(false)
  }

  const handleLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    onLoad?.(e)
  }

  useIsomorphicLayoutEffect(() => {
    if (inView) {
      setImageSrc(src)
      setIsLoading(false)
    }
  }, [inView])

  return (
    <StyledImg
      ref={ref}
      src={isLoading || hasError ? fallbackImage : imageSrc}
      onLoad={handleLoad}
      onError={handleError}
      width={width}
      height={height}
      aspectRatio={aspectRatio}
      objectFit={objectFit}
      style={style}
      {...rest}
    />
  )
}

const StyledImg = styled.img<{
  width?: string | number
  height?: string | number
  aspectRatio?: string
  objectFit?: string
}>`
  vertical-align: top;
  width: ${({ width }) => convertTypeToStrPx(width) ?? '100%'};
  height: ${({ height }) => convertTypeToStrPx(height) ?? '100%'};
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  object-fit: ${({ objectFit }) => objectFit};
`
