import { styled, useTheme } from 'styled-components'
import { FitpetIcon } from '@/components/common/icons/fitpet-icon'
import { Typo } from '@/components/common/typography'
import { CardBodyHorizontal } from '@/components/domains/products/new-product-card/CardBodyHorizontal'
import { CardBodyVertical } from '@/components/domains/products/new-product-card/CardBodyVertical'
import { HorizontalProductCard } from '@/components/domains/products/new-product-card/horizontal-product-card'
import {
  BasicLabelType,
  CARD_BODY_PRICE_INFO_TYPES,
  CARD_BODY_VERTICAL_SIZES,
  ProductCardSize,
  THUMBNAIL_STATES,
  ThumbnailState,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import { VerticalProductCard } from '@/components/domains/products/new-product-card/vertical-product-card'
import ROUTES from '@/constants/legacy/constRoutes'
import { useProductListEventTracker } from '@/containers/event-tracker/product-list.event-tracker'
import { useOpenProductOptionChoiceBottomSheet } from '@/containers/hooks/cart/useOpenProductOptionChoiceBottomSheet'
import useCustomRouter from '@/containers/hooks/useCustomRouter'
import { BottomSheetEventTrackerType } from '@/types/bottom-sheet-event-tracker.type'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { encodeId } from '@/utils/utilApi'
import { currencyText } from '@/utils/utilNumber'

const MinimumSalesQuantity = 50

const PriceDownNudgeText = {
  recently: '최근 본 가격보다 내려갔어요',
  like: '찜했을 때 가격보다 내려갔어요',
}

interface ProductCardProps {
  cartType?: 'VERTICAL' | 'HORIZONTAL'
  product: ProductTypeDefinedByFrontend
  size: ProductCardSize
  thumbnailState?: ThumbnailState
  label?: BasicLabelType
  rankingNumber?: number
  hasCartButton?: boolean
  priceDownNudge?: {
    isShow: boolean
    location: 'recently' | 'like'
  }
  trackerData?: BottomSheetEventTrackerType
  productCardTrackerData?: { location?: string }
  isExpectedMileageVisible?: boolean
  isSalesQuantityVisible?: boolean
  onClickProductCard?: () => void
  onClickCartButton?: () => void
  callbackAddToCart?: (ids?: number[]) => void
  callbackLike?: () => void
}

export const NewProductCard = ({
  cartType = 'VERTICAL',
  product,
  size,
  thumbnailState,
  label,
  rankingNumber,
  hasCartButton = false,
  priceDownNudge,
  trackerData,
  productCardTrackerData,
  isExpectedMileageVisible = false,
  isSalesQuantityVisible = false,
  onClickProductCard,
  onClickCartButton,
  callbackAddToCart,
  callbackLike,
}: ProductCardProps) => {
  const { color, iconSize } = useTheme()
  const { push } = useCustomRouter()
  const { openProductOptionChoiceBottomSheet } = useOpenProductOptionChoiceBottomSheet()
  const { trackClickListStartPurchaseButtonEvent } = useProductListEventTracker()

  const {
    id,
    promotion,
    thumbnail,
    name,
    discountRate,
    price,
    salePrice,
    reviewCount,
    reviewScore,
    maxOptionSkuCount,
    pricePerSku,
    isShowBundledMessage: isBundledMessage,
    hasApplicableProductCoupon,
    hasDiscount,
    isLiked,
    isRealSoldOut,
    isNotAvailable,
    thumbnailData,
    isPriceDown,
    expectedMileage,
    salesQuantity,
  } = product

  const hasSufficientSalesQuantity = salesQuantity && salesQuantity > MinimumSalesQuantity
  const hasSufficientExpectedMileage = expectedMileage && expectedMileage > 0

  const handleClickProductCard = () => {
    onClickProductCard?.()
    push({
      pathname: ROUTES.PRODUCTS.DETAIL,
      query: {
        productId: id,
        ...(promotion?.id && { promotion: encodeId('ProductPromotionType', promotion?.id) }),
        ...(productCardTrackerData?.location && { location: productCardTrackerData?.location }),
      },
    })
  }

  const handleClickCartButton = () => {
    onClickCartButton?.()
    trackClickListStartPurchaseButtonEvent({
      product,
      addLocation: trackerData?.addLocation,
      sectionName: trackerData?.sectionName,
      listIndex: trackerData?.listIndex,
      isNudge: trackerData?.isNudge,
      location: trackerData?.location,
      searchKeyword: trackerData?.searchKeyword,
      searchLocation: trackerData?.searchLocation,
      listType: trackerData?.listType,
      originProductId: trackerData?.originProductId,
      originProductName: trackerData?.originProductName,
      categoryName: trackerData?.categoryName,
      categoryTypeName: trackerData?.categoryTypeName,
    })
    openProductOptionChoiceBottomSheet({ product, trackerData, callback: callbackAddToCart })
  }

  if (cartType === 'HORIZONTAL') {
    return (
      <StyledHorizontalProductCardWrapper>
        <HorizontalProductCard onClick={handleClickProductCard}>
          <HorizontalProductCard.Thumbnail
            productId={id}
            productName={name}
            productPromotionScheme={promotion}
            thumbnailState={thumbnailState || THUMBNAIL_STATES.basicLabel}
            imageUrl={thumbnail}
            isLiked={isLiked}
            isRealSoldOut={isRealSoldOut}
            isNotAvailable={isNotAvailable}
            rankingNumber={rankingNumber}
            basicLabel={label}
            trackingData={thumbnailData}
            callbackLike={callbackLike}
          />
          <HorizontalProductCard.BodyContainer>
            <HorizontalProductCard.Body>
              <CardBodyHorizontal>
                <CardBodyHorizontal.Name>{name}</CardBodyHorizontal.Name>
                <CardBodyHorizontal.PriceInfo
                  hasDiscount={hasDiscount}
                  price={price}
                  salePrice={salePrice}
                  discountRate={discountRate}
                  type={
                    hasApplicableProductCoupon ? CARD_BODY_PRICE_INFO_TYPES.COUPON : CARD_BODY_PRICE_INFO_TYPES.DEFAULT
                  }
                />
                {priceDownNudge?.isShow && isPriceDown && (
                  <CardBodyHorizontal.PriceDownNudge>
                    <Typo variant="Body5 Bold" color={color.red500}>
                      {PriceDownNudgeText[priceDownNudge.location]}
                    </Typo>
                    <FitpetIcon type="ic-arrow-down-red-gradation" size={iconSize.size16} />
                  </CardBodyHorizontal.PriceDownNudge>
                )}
                {isBundledMessage && (
                  <CardBodyHorizontal.DiscountedBulkPrice>
                    {maxOptionSkuCount}개 구매 시 각 {currencyText(pricePerSku)}
                  </CardBodyHorizontal.DiscountedBulkPrice>
                )}
                {reviewScore > 0 && (
                  <CardBodyHorizontal.ReviewInfo>
                    <CardBodyHorizontal.ReviewScore>{reviewScore}</CardBodyHorizontal.ReviewScore>
                    <CardBodyHorizontal.ReviewCount>
                      ({reviewCount.toLocaleString('ko-KR')})
                    </CardBodyHorizontal.ReviewCount>
                  </CardBodyHorizontal.ReviewInfo>
                )}
              </CardBodyHorizontal>
            </HorizontalProductCard.Body>

            {hasCartButton && (
              <HorizontalProductCard.CartButton
                disabled={isRealSoldOut || isNotAvailable}
                onClick={handleClickCartButton}
              />
            )}
          </HorizontalProductCard.BodyContainer>
        </HorizontalProductCard>
      </StyledHorizontalProductCardWrapper>
    )
  }

  return (
    <StyledVerticalProductCardWrapper>
      <VerticalProductCard size={size} onClick={handleClickProductCard}>
        <VerticalProductCard.Thumbnail
          productId={id}
          productName={name}
          productPromotionScheme={promotion}
          thumbnailState={thumbnailState || THUMBNAIL_STATES.basicLabel}
          imageUrl={thumbnail}
          isLiked={isLiked}
          isRealSoldOut={isRealSoldOut}
          isNotAvailable={isNotAvailable}
          rankingNumber={rankingNumber}
          basicLabel={label}
          trackingData={thumbnailData}
          callbackLike={callbackLike}
        />

        {hasCartButton && (
          <VerticalProductCard.CartButton disabled={isRealSoldOut || isNotAvailable} onClick={handleClickCartButton} />
        )}

        <VerticalProductCard.Body>
          <CardBodyVertical size={size || CARD_BODY_VERTICAL_SIZES.lg}>
            <CardBodyVertical.Name>{name}</CardBodyVertical.Name>
            <CardBodyVertical.PriceInfo
              hasDiscount={hasDiscount}
              price={price}
              salePrice={salePrice}
              discountRate={discountRate}
              type={hasApplicableProductCoupon ? CARD_BODY_PRICE_INFO_TYPES.COUPON : CARD_BODY_PRICE_INFO_TYPES.DEFAULT}
            />
            {isBundledMessage && (
              <CardBodyVertical.DiscountedBulkPrice>
                {maxOptionSkuCount}개 구매 시 각 {currencyText(pricePerSku)}
              </CardBodyVertical.DiscountedBulkPrice>
            )}
            {isExpectedMileageVisible && !!hasSufficientExpectedMileage && (
              <CardBodyVertical.MaxMileage>
                최대 {Number(expectedMileage).toLocaleString()}원 적립
              </CardBodyVertical.MaxMileage>
            )}
            {isSalesQuantityVisible && !!hasSufficientSalesQuantity && (
              <CardBodyVertical.TotalPurchases>
                누적구매수 {Number(salesQuantity).toLocaleString()}
              </CardBodyVertical.TotalPurchases>
            )}

            {reviewScore > 0 && (
              <CardBodyVertical.ReviewInfo>
                <CardBodyVertical.ReviewScore>{reviewScore}</CardBodyVertical.ReviewScore>
                <CardBodyVertical.ReviewCount>({reviewCount.toLocaleString('ko-KR')})</CardBodyVertical.ReviewCount>
              </CardBodyVertical.ReviewInfo>
            )}
          </CardBodyVertical>
        </VerticalProductCard.Body>
      </VerticalProductCard>
    </StyledVerticalProductCardWrapper>
  )
}

const StyledHorizontalProductCardWrapper = styled.div`
  position: relative;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const StyledVerticalProductCardWrapper = styled.div`
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
